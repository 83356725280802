<template>
    <div>
   
    <b-container fluid>
        <Sidebar></Sidebar>
         <ul class="background">
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
</ul>
        <div class="">
            <router-view></router-view>
        </div>

    </b-container>

    </div>
</template>

<script>


import Sidebar from "./Sidebar.vue"

export default {
    components: {
      Sidebar
    },
}

</script>

<style>

    .dashboard_conteiner {
        margin-left:250px!important;
    }

</style>